import React from "react";
import ReactDOM from "react-dom";
import WebViz from "./WebViz";

function App() {
  return (
      <WebViz />
  );
}

export default App;
